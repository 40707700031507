import React from "react";
import styled from "styled-components";

const BadgeWrapper = styled.div`
	width: 80px;
	height: 80px;
	transition: all 0.2s ease-in-out;
	&:hover {
		transform: scale(1.1);
	}
`;

export const ColorBadge = ({ active, color }) => {
	const activeSVG = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 -0.5 150 94"
			shape-rendering="crispEdges"
		>
			<path
				stroke={color}
				d="M18 0h19M18 1h18M18 2h19M18 3h18M18 4h13M32 4h5M18 5h19M18 6h6M25 6h12M18 7h19M18 8h19M18 9h19M18 10h6M25 10h12M18 11h4M23 11h14M18 12h19M18 13h19M18 14h19M18 15h19M18 16h19M18 17h19M18 18h11M30 18h7M56 19h25M82 19h12M56 20h13M70 20h10M81 20h13M56 21h5M62 21h32M56 22h13M70 22h9M80 22h5M86 22h6M93 22h1M56 23h38M56 24h38M56 25h7M64 25h1M66 25h28M56 26h12M69 26h25M56 27h4M61 27h11M73 27h1M75 27h19M56 28h38M56 29h38M56 30h15M72 30h3M76 30h18M56 31h22M79 31h15M56 32h38M56 33h38M56 34h19M76 34h18M56 35h2M59 35h35M56 36h38M56 37h38M37 38h16M54 38h2M37 39h19M37 40h19M37 41h19M37 42h19M37 43h19M37 44h19M37 45h19M37 46h19M37 47h5M43 47h13M37 48h19M37 49h19M37 50h2M40 50h16M37 51h18M37 52h19M37 53h8M46 53h10M37 54h19M37 55h6M44 55h12M37 56h19M37 57h15M54 57h2M37 58h19M37 59h19M37 60h19M37 61h19M37 62h19M37 63h5M43 63h13M37 64h19M37 65h19M37 66h19M37 67h19M37 68h19M37 69h19M37 70h4M42 70h14M37 71h19M37 72h19M37 73h19M37 74h19M56 75h10M67 75h27M56 76h38M56 77h38M56 78h38M56 79h38M56 80h38M56 81h25M82 81h9M92 81h2M56 82h9M66 82h26M93 82h1M56 83h16M73 83h13M87 83h7M56 84h38M56 85h38M56 86h27M84 86h10M56 87h38M56 88h38M56 89h34M91 89h3M56 90h38M56 91h7M64 91h30M56 92h11M68 92h26M56 93h12M69 93h16M86 93h8"
			/>
			<path
				stroke={color}
				d="M36 1h1M36 3h1M24 6h1M24 10h1M81 19h1M69 20h1M69 22h1M63 25h1M60 27h1M72 27h1M75 30h1M78 31h1M75 34h1M42 47h1M39 50h1M45 53h1M42 63h1M66 75h1M81 81h1M72 83h1M90 89h1M63 91h1"
			/>
			<path
				stroke={color}
				d="M31 4h1M22 11h1M61 21h1M79 22h1M85 22h1M58 35h1M55 51h1M43 55h1M52 57h1M91 81h1M67 92h1M85 93h1"
			/>
			<path
				stroke={color}
				d="M29 18h1M80 20h1M92 22h1M65 25h1M68 26h1M74 27h1M71 30h1M53 38h1M53 57h1M41 70h1M65 82h1M92 82h1M86 83h1M83 86h1M68 93h1"
			/>
			<path
				stroke="#ffffff"
				d="M37 19h3M41 19h5M47 19h9M37 20h11M50 20h6M37 21h19M37 22h7M45 22h11M37 23h19M37 24h19M37 25h19M37 26h19M37 27h19M37 28h7M45 28h11M37 29h19M37 30h19M37 31h1M39 31h5M45 31h11M37 32h19M37 33h17M55 33h1M37 34h19M37 35h19M37 36h1M39 36h17M37 37h19M94 56h1M96 56h7M104 56h9M94 57h6M101 57h12M94 58h19M94 59h12M107 59h6M94 60h13M108 60h5M94 61h19M94 62h19M94 63h19M94 64h10M105 64h8M95 65h18M94 66h15M110 66h3M94 67h19M94 68h19M94 69h19M94 70h19M94 71h19M94 72h9M104 72h9M94 73h3M98 73h15M94 74h19"
			/>
			<path
				stroke="#fffeff"
				d="M40 19h1M46 19h1M49 20h1M103 56h1M100 57h1M106 59h1M94 65h1M109 66h1M103 72h1M97 73h1"
			/>
			<path stroke="#feffff" d="M48 20h1M54 33h1" />
			<path
				stroke="#fffffe"
				d="M44 22h1M44 28h1M38 31h1M44 31h1M38 36h1M95 56h1M107 60h1M104 64h1"
			/>
		</svg>
	);

	const inactiveSVG = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 -0.5 150 94"
			shape-rendering="crispEdges"
		>
			<path
				stroke="#dddddd"
				d="M18 0h17M36 0h1M18 1h1M20 1h17M18 2h2M21 2h16M18 3h10M29 3h8M18 4h19M18 5h19M18 6h19M18 7h19M18 8h19M18 9h17M36 9h1M18 10h19M18 11h2M21 11h16M18 12h19M18 13h19M18 14h19M18 15h9M28 15h9M18 16h19M18 17h19M18 18h19M56 19h38M56 20h27M84 20h6M92 20h2M56 21h38M56 22h21M78 22h16M56 23h27M84 23h10M56 24h25M82 24h12M56 25h30M87 25h7M56 26h6M63 26h31M56 27h38M56 28h38M56 29h8M65 29h29M56 30h14M71 30h23M56 31h15M72 31h22M56 32h38M56 33h3M60 33h34M56 34h38M56 35h38M56 36h38M56 37h25M82 37h12M37 38h19M37 39h19M37 40h19M37 41h19M37 42h19M37 43h19M37 44h19M37 45h19M37 46h19M37 47h4M42 47h1M44 47h12M37 48h11M49 48h7M37 49h19M37 50h19M37 51h19M37 52h4M42 52h14M37 53h2M40 53h16M37 54h19M37 55h1M39 55h13M53 55h3M37 56h16M54 56h2M37 57h6M44 57h12M37 58h19M37 59h19M37 60h19M37 61h19M37 62h19M37 63h19M37 64h19M37 65h19M37 66h14M52 66h4M37 67h19M37 68h19M37 69h19M37 70h19M37 71h16M54 71h2M37 72h19M37 73h19M37 74h7M45 74h11M56 75h38M56 76h38M56 77h21M78 77h16M56 78h38M56 79h3M60 79h1M62 79h9M72 79h22M56 80h38M56 81h7M64 81h30M56 82h21M78 82h7M86 82h8M56 83h38M56 84h2M59 84h11M71 84h23M56 85h38M56 86h38M56 87h38M56 88h38M56 89h22M79 89h15M56 90h26M83 90h8M92 90h2M56 91h12M69 91h25M56 92h38M56 93h38"
			/>
			<path
				stroke="#dddddc"
				d="M35 0h1M20 2h1M35 9h1M20 11h1M83 20h1M77 22h1M83 23h1M86 25h1M62 26h1M71 31h1M59 33h1M41 47h1M41 52h1M38 55h1M53 56h1M53 71h1M44 74h1M77 77h1M59 79h1M71 79h1M77 82h1M68 91h1"
			/>
			<path
				stroke="#dddcdd"
				d="M19 1h1M28 3h1M91 20h1M64 29h1M70 30h1M43 47h1M52 55h1M43 57h1M61 79h1M85 82h1M58 84h1M70 84h1M82 90h1M91 90h1"
			/>
			<path
				stroke="#dcdddd"
				d="M27 15h1M90 20h1M81 24h1M81 37h1M48 48h1M39 53h1M51 66h1M63 81h1M78 89h1"
			/>
			<path
				stroke="#2b2d2c"
				d="M37 19h6M44 19h12M37 20h19M37 21h19M37 22h12M50 22h6M37 23h19M37 24h19M37 25h19M37 26h19M37 27h19M37 28h19M37 29h19M37 30h19M37 31h19M37 32h19M37 33h19M37 34h16M54 34h2M37 35h19M37 36h19M37 37h17M55 37h1M94 56h19M94 57h19M94 58h19M94 59h1M96 59h17M94 60h19M94 61h19M94 62h19M94 63h19M94 64h19M94 65h19M94 66h19M94 67h19M94 68h3M98 68h6M105 68h8M94 69h12M107 69h6M94 70h19M94 71h19M94 72h3M98 72h15M94 73h19M94 74h19"
			/>
			<path stroke="#2b2c2c" d="M43 19h1M49 22h1M97 68h1M106 69h1M97 72h1" />
			<path stroke="#2b2d2d" d="M53 34h1M95 59h1M104 68h1" />
			<path stroke="#2a2d2c" d="M54 37h1" />
		</svg>
	);

	return <BadgeWrapper>{active ? activeSVG : inactiveSVG}</BadgeWrapper>;
};

export const EditionBadge = ({ active }) => {
	const activeSVG = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="10 -5 150 94"
			shape-rendering="crispEdges"
		>
			<path
				stroke="#ffffff"
				d="M112 0h15M128 0h3M112 1h19M112 2h19M112 3h19M112 4h7M120 4h11M112 5h19M112 6h10M123 6h8M112 7h19M112 8h16M129 8h2M112 9h11M124 9h7M112 10h19M112 11h19M112 12h19M112 13h19M112 14h19M112 15h19M112 16h19M112 17h19M112 18h4M117 18h14M131 19h19M131 20h19M131 21h19M131 22h10M142 22h8M131 23h19M131 24h19M131 25h5M137 25h13M131 26h6M138 26h12M131 27h19M131 28h19M131 29h7M139 29h11M131 30h3M135 30h15M131 31h19M131 32h19M131 33h19M131 34h8M140 34h10M131 35h19M131 36h19M131 37h19"
			/>
			<path stroke="#fffeff" d="M127 0h1M136 25h1M139 34h1" />
			<path
				stroke="#fffffe"
				d="M119 4h1M122 6h1M128 8h1M116 18h1M137 26h1M134 30h1"
			/>
			<path stroke="#feffff" d="M123 9h1M141 22h1M138 29h1" />
			<path
				stroke="#d44e96"
				d="M37 19h9M47 19h9M75 19h19M37 20h19M75 20h5M81 20h13M37 21h19M75 21h19M37 22h19M75 22h19M38 23h18M75 23h19M37 24h19M75 24h19M37 25h14M52 25h4M76 25h17M37 26h19M75 26h19M37 27h12M50 27h1M52 27h4M75 27h19M37 28h19M75 28h19M37 29h19M75 29h13M89 29h5M37 30h19M75 30h19M37 31h19M75 31h5M81 31h13M37 32h10M48 32h8M75 32h19M37 33h19M75 33h19M37 34h10M48 34h8M75 34h19M37 35h3M41 35h3M45 35h11M75 35h19M37 36h19M75 36h13M89 36h5M37 37h19M75 37h7M83 37h11M56 38h19M56 39h10M67 39h8M56 40h19M56 41h10M67 41h8M56 42h19M56 43h19M56 44h11M68 44h7M56 45h19M56 46h19M56 47h19M56 48h10M67 48h8M56 49h19M56 50h19M56 51h19M56 52h15M72 52h3M56 53h19M56 54h1M58 54h14M73 54h2M56 55h19M37 56h3M41 56h15M75 56h19M37 57h19M75 57h19M37 58h19M75 58h19M37 59h19M75 59h19M37 60h19M75 60h19M37 61h19M75 61h6M82 61h12M37 62h19M75 62h19M37 63h19M75 63h17M93 63h1M37 64h19M75 64h19M37 65h15M53 65h3M75 65h19M37 66h19M75 66h19M37 67h7M45 67h1M47 67h9M76 67h18M37 68h19M75 68h19M37 69h19M75 69h19M38 70h2M41 70h15M75 70h18M37 71h19M75 71h19M38 72h18M75 72h19M37 73h6M44 73h12M75 73h19M37 74h19M75 74h19M37 75h19M75 75h9M85 75h9"
			/>
			<path
				stroke="#d44f96"
				d="M46 19h1M37 23h1M49 27h1M88 29h1M40 35h1M88 36h1M82 37h1M67 44h1M40 56h1M52 65h1M46 67h1M37 70h1M40 70h1M37 72h1M43 73h1"
			/>
			<path
				stroke="#d44e97"
				d="M80 20h1M80 31h1M47 32h1M47 34h1M44 35h1M71 52h1M92 63h1M44 67h1"
			/>
			<path
				stroke="#d54e96"
				d="M51 25h1M75 25h1M93 25h1M51 27h1M66 39h1M66 41h1M66 48h1M57 54h1M72 54h1M81 61h1M75 67h1M93 70h1M84 75h1"
			/>
		</svg>
	);

	const inactiveSVG = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="10 -5 150 94"
			shape-rendering="crispEdges"
		>
			<path
				stroke="#2b2d2c"
				d="M112 0h19M112 1h19M112 2h19M112 3h13M126 3h5M112 4h14M127 4h4M112 5h19M112 6h7M120 6h11M112 7h19M112 8h19M112 9h19M112 10h19M112 11h19M112 12h19M112 13h2M115 13h16M112 14h7M120 14h5M126 14h5M112 15h2M115 15h16M112 16h19M112 17h19M112 18h19M131 19h19M131 20h12M144 20h3M148 20h2M131 21h19M131 22h19M131 23h19M131 24h19M131 25h19M131 26h16M148 26h2M131 27h19M131 28h19M131 29h19M131 30h19M131 31h9M141 31h9M131 32h19M131 33h19M131 34h19M131 35h3M135 35h15M131 36h19M131 37h19"
			/>
			<path
				stroke="#2b2d2d"
				d="M125 3h1M119 6h1M119 14h1M125 14h1M143 20h1M140 31h1M134 35h1"
			/>
			<path stroke="#2a2d2c" d="M126 4h1M114 13h1M114 15h1M147 20h1M147 26h1" />
			<path
				stroke="#dddddd"
				d="M37 19h19M75 19h19M37 20h19M75 20h5M81 20h13M37 21h19M75 21h8M84 21h10M37 22h19M75 22h19M37 23h19M75 23h6M83 23h11M37 24h19M75 24h7M83 24h11M37 25h17M55 25h1M75 25h19M37 26h19M75 26h19M37 27h19M75 27h19M37 28h19M75 28h6M82 28h12M37 29h19M75 29h14M90 29h4M37 30h17M55 30h1M75 30h19M37 31h19M75 31h3M79 31h15M37 32h19M75 32h19M37 33h19M75 33h19M37 34h19M75 34h3M79 34h14M37 35h3M41 35h8M50 35h6M75 35h19M37 36h19M75 36h19M37 37h19M75 37h19M56 38h19M56 39h17M74 39h1M56 40h19M56 41h19M56 42h19M56 43h19M56 44h18M56 45h10M67 45h8M56 46h15M72 46h3M56 47h19M56 48h17M74 48h1M56 49h19M56 50h12M69 50h6M56 51h19M56 52h19M56 53h19M56 54h3M60 54h15M56 55h19M37 56h19M75 56h19M37 57h19M75 57h19M37 58h1M39 58h17M75 58h19M37 59h19M75 59h19M37 60h19M75 60h16M92 60h2M37 61h19M75 61h14M90 61h4M37 62h19M75 62h19M37 63h19M75 63h19M37 64h2M40 64h16M75 64h19M37 65h19M75 65h13M89 65h5M37 66h19M75 66h3M79 66h15M37 67h19M75 67h19M38 68h14M53 68h3M75 68h19M37 69h19M75 69h19M37 70h4M42 70h14M75 70h19M37 71h19M75 71h19M37 72h19M75 72h19M37 73h3M41 73h15M75 73h1M77 73h17M37 74h6M44 74h5M50 74h6M75 74h9M85 74h9M37 75h19M75 75h19"
			/>
			<path
				stroke="#dddddc"
				d="M80 20h1M83 21h1M89 29h1M74 44h1M71 46h1M68 50h1M59 54h1M38 58h1M89 61h1M41 70h1"
			/>
			<path
				stroke="#dcdddd"
				d="M81 23h1M54 25h1M81 28h1M54 30h1M78 31h1M78 34h1M93 34h1M66 45h1M39 64h1M78 66h1M84 74h1"
			/>
			<path
				stroke="#dddcdd"
				d="M82 23h1M82 24h1M40 35h1M49 35h1M73 39h1M73 48h1M91 60h1M88 65h1M37 68h1M52 68h1M40 73h1M76 73h1M43 74h1M49 74h1"
			/>
		</svg>
	);

	return <BadgeWrapper>{active ? activeSVG : inactiveSVG}</BadgeWrapper>;
};

export const DonationBadge = ({ active }) => {
	const activeSVG = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="-20 -1 150 94"
			shape-rendering="crispEdges"
		>
			<path
				stroke="orange"
				d="M75 0h19M75 1h19M75 2h19M75 3h19M75 4h19M75 5h19M75 6h19M75 7h19M75 8h19M75 9h19M76 10h18M75 11h19M75 12h19M75 13h8M84 13h3M88 13h6M75 14h8M84 14h10M75 15h19M75 16h19M75 17h19M75 18h8M84 18h10M37 19h4M42 19h1M44 19h31M37 20h16M55 20h20M37 21h37M37 22h38M37 23h38M37 24h38M37 25h38M37 26h38M37 27h38M37 28h38M37 29h25M63 29h12M37 30h28M66 30h9M37 31h38M37 32h38M37 33h38M37 34h38M37 35h38M37 36h38M37 37h36M74 37h1M18 38h19M18 39h19M18 40h13M32 40h5M18 41h19M18 42h5M24 42h13M18 43h19M18 44h19M18 45h19M18 46h19M18 47h19M18 48h19M18 49h19M18 50h19M18 51h19M18 52h1M20 52h17M18 53h19M18 54h19M18 55h19M37 56h26M64 56h11M37 57h19M57 57h7M65 57h10M37 58h18M56 58h19M38 59h37M37 60h3M41 60h34M37 61h38M37 62h5M43 62h32M37 63h38M37 64h38M37 65h3M41 65h34M37 66h38M37 67h38M37 68h38M37 69h22M60 69h15M37 70h5M43 70h32M37 71h38M37 72h19M57 72h18M37 73h38M37 74h38"
			/>
			<path
				stroke="orange"
				d="M75 10h1M87 13h1M54 20h1M63 56h1M42 62h1M42 70h1"
			/>
			<path
				stroke="orange"
				d="M83 13h1M83 14h1M83 18h1M41 19h1M53 20h1M74 21h1M62 29h1M65 30h1M23 42h1M56 57h1M59 69h1M56 72h1"
			/>
			<path
				stroke="#ffffff"
				d="M18 19h9M28 19h9M18 20h15M34 20h3M18 21h19M18 22h19M18 23h19M18 24h19M18 25h19M18 26h14M33 26h4M18 27h19M18 28h19M18 29h19M18 30h3M22 30h15M18 31h19M18 32h19M18 33h19M18 34h19M18 35h3M22 35h15M18 36h19M18 37h19M75 75h19M75 76h19M75 77h19M75 78h19M75 79h19M75 80h19M75 81h5M81 81h13M75 82h19M75 83h11M87 83h7M75 84h19M75 85h19M75 86h1M77 86h16M75 87h19M75 88h19M75 89h19M75 90h19M75 91h19M75 92h19M75 93h5M81 93h13"
			/>
			<path stroke="#feffff" d="M27 19h1M33 20h1M21 30h1M21 35h1M93 86h1" />
			<path
				stroke="orange"
				d="M43 19h1M73 37h1M31 40h1M19 52h1M64 57h1M55 58h1M37 59h1M40 60h1M40 65h1"
			/>
			<path stroke="#fffffe" d="M32 26h1M80 81h1M86 83h1M80 93h1" />
			<path stroke="#fffeff" d="M76 86h1" />
		</svg>
	);

	const inactiveSVG = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="-20 -1 150 94"
			shape-rendering="crispEdges"
		>
			<path
				stroke="#dddddd"
				d="M75 0h5M81 0h13M75 1h9M85 1h9M75 2h19M75 3h19M75 4h19M75 5h19M75 6h9M85 6h9M75 7h15M91 7h3M75 8h19M75 9h16M92 9h2M75 10h19M77 11h17M75 12h1M77 12h17M75 13h19M75 14h19M75 15h12M88 15h6M75 16h19M75 17h19M75 18h19M37 19h38M37 20h38M37 21h9M47 21h25M73 21h2M37 22h20M58 22h17M37 23h38M37 24h11M49 24h3M53 24h6M60 24h7M68 24h7M37 25h38M37 26h38M37 27h38M37 28h22M60 28h11M72 28h3M37 29h6M44 29h31M37 30h38M37 31h38M37 32h21M59 32h16M37 33h5M43 33h32M37 34h22M60 34h15M37 35h38M37 36h38M37 37h38M18 38h19M18 39h19M18 40h18M18 41h19M18 42h19M18 43h19M18 44h19M18 45h19M18 46h19M18 47h19M18 48h19M18 49h2M21 49h11M33 49h4M18 50h11M30 50h7M18 51h14M33 51h4M18 52h19M18 53h19M18 54h19M18 55h15M34 55h3M37 56h38M37 57h38M38 58h18M57 58h18M37 59h38M37 60h38M37 61h38M37 62h38M37 63h2M40 63h17M58 63h1M60 63h15M37 64h13M51 64h24M37 65h2M40 65h35M37 66h38M37 67h38M37 68h1M39 68h5M45 68h30M37 69h38M37 70h31M69 70h6M37 71h1M39 71h36M38 72h22M61 72h14M37 73h38M37 74h38"
			/>
			<path
				stroke="#dddddc"
				d="M80 0h1M59 24h1M59 28h1M71 28h1M59 34h1M20 49h1M32 49h1M29 50h1M32 51h1M56 58h1M59 63h1M50 64h1M38 68h1M44 68h1M68 70h1M38 71h1"
			/>
			<path
				stroke="#dcdddd"
				d="M84 1h1M84 6h1M90 7h1M75 11h1M87 15h1M72 21h1M57 22h1M48 24h1M42 33h1M36 40h1M33 55h1M39 63h1M57 63h1M39 65h1M60 72h1"
			/>
			<path
				stroke="#dddcdd"
				d="M91 9h1M76 11h1M76 12h1M46 21h1M52 24h1M67 24h1M43 29h1M58 32h1M37 58h1M37 72h1"
			/>
			<path
				stroke="#2b2d2c"
				d="M18 19h12M31 19h6M18 20h2M21 20h16M18 21h19M18 22h19M18 23h19M18 24h19M18 25h13M32 25h5M18 26h19M18 27h19M18 28h19M18 29h19M18 30h19M18 31h19M18 32h19M18 33h19M19 34h18M18 35h16M35 35h1M18 36h16M35 36h2M18 37h19M75 75h19M75 76h19M75 77h19M75 78h8M84 78h10M75 79h19M75 80h5M82 80h2M85 80h9M75 81h19M75 82h19M75 83h19M75 84h19M75 85h13M89 85h5M75 86h19M75 87h19M75 88h19M75 89h19M75 90h19M75 91h19M75 92h19M75 93h19"
			/>
			<path stroke="#2a2d2c" d="M30 19h1M18 34h1M36 35h1M81 80h1M84 80h1" />
			<path stroke="#2b2d2d" d="M20 20h1M83 78h1M80 80h1" />
			<path stroke="#2b2c2c" d="M31 25h1M34 35h1M34 36h1M88 85h1" />
		</svg>
	);

	return <BadgeWrapper>{active ? activeSVG : inactiveSVG}</BadgeWrapper>;
};
